<template>

    <div>
      <template>
        <div id="breadcrumb-alignment" class="mb-1">
          <div class="d-flex justify-content-start breadcrumb-wrapper">
            <b-breadcrumb
              :items="breadCrumb()"
            />
          </div>
        </div>
      </template>
      <!-- Table Container Card -->
  
      <div v-if="checkPermission($route.name, 'View')" class="mt-2">
        
        <b-row>
          <b-col md="5">
  
            <b-card no-body class="custom-card-height">
            
              <b-card-body>
                
                    
                    <b-row class="mb-2">
                      <b-col cols="12" md="12" class="mb-2">
                        <!-- <v-select
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="task_types"
                          class="w-100"
                          placeholder="Select Report"
                          @input="frequencyData = null; crew = ''; filterTable();"
                          v-model="task_type"
                          :clearable="false"
                        /> -->
                        <div class="demo-inline-spacing">
                          <b-form-radio class="mb-0 mt-0" name="task_type" value="Service Request" v-model="task_type" @change="frequencyData = null; crew = ''; filterTable();">
                            Service Request
                          </b-form-radio>
                          <b-form-radio class="mb-0 mt-0" name="task_type" value="Defects" v-model="task_type" @change="frequencyData = null; crew = ''; filterTable();">
                            Defect
                          </b-form-radio>
                        </div>

                      </b-col>

                      <b-col cols="12" md="12" class="mb-2">
                        <v-select
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="sites"
                          label="site_name"
                          class="w-100"
                          placeholder="Project Site"
                          @input="resetData"
                          v-model="siteData"
                          :clearable="false"
                        />
                      </b-col>
  
                      <b-col cols="12" md="12" class="mb-2">
                        <v-select
                            v-model="locations"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            multiple
                            label="name"
                            :options="alllocations"
                            placeholder="Select Locations"
                            @input="filterTable"
                            :close-on-select=false
                            :clear-on-select=false
                            class="multiselect_muliple_options"
                          />
                      </b-col>
                      
                      <b-col cols="12" md="12" class="mb-2" v-if="task_type != 'Defects'">
                        <v-select
                          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                          :options="frequencies"
                          :disabled="task_type == 'Defects'"
                          class="w-100"
                          placeholder="Type"
                          @input="filterTable"
                          v-model="frequencyData"
                          :clearable="true"
                        />
                      </b-col>
  
                      <b-col cols="12" md="12" class="mb-2" v-if="task_type != 'Defects'">
                        <b-form-select v-model="crew" @change="filterTable" :disabled="task_type == 'Defects'">
                            <b-form-select-option value="">Select Crew</b-form-select-option>
                            <b-form-select-option :value="crew._id" v-for="crew in allcrews" :key="crew._id">{{crew.full_name}}</b-form-select-option>
                        </b-form-select>
                      </b-col>
  
                      <b-col cols="12" md="12" class="mb-2">
                        <b-form-select v-model="status" @change="filterTable">
                            <b-form-select-option value="">Status</b-form-select-option>
                            <b-form-select-option value="open" >Open</b-form-select-option>
                            <b-form-select-option value="in_progress" >In Progress</b-form-select-option>
                            <b-form-select-option value="validate" >Validate</b-form-select-option>
                            <b-form-select-option value="closed" >Closed</b-form-select-option>
                            <b-form-select-option value="failed" >Failed</b-form-select-option>
                        </b-form-select>
                      </b-col>
                      
                      <b-col cols="12" md="12" class="mb-2">
                        <div class="d-flex align-items-center">				          
                          <flat-pickr
                            v-model="date_range"
                            :config="date_config"
                            class="form-control flat-picker"
                            placeholder="Select Date Range"
                            @on-change="setDate()"
                          />
                        </div>
                      </b-col>
  
                      <!-- <b-col cols="12" md="4" class="mb-2">
                        <b-button
                            variant="danger"
                            class="mt-0"
                            @click="resetall"
                          >
                          <span class="text-nowrap">Reset</span>
                        </b-button>
  
                      </b-col>  -->
                    </b-row>
                  
              </b-card-body>
            </b-card>
          </b-col>
  
          <b-col md="4">
  
            <b-card no-body class="custom-card-height">
              <b-card-body>
                
                <!-- apex chart -->
                <vue-apex-charts type="pie" height="200" class="mt-0 mb-1" :options="customersPie.chartOptions" :series="series" />

                <!-- list group -->
                <div class="pt-25">
                  <div
                    v-for="(data,index) in chartData.listData"
                    :key="index"
                    class="d-flex justify-content-between"
                    :class="index === Object.keys(chartData.listData).length - 1 ? '':'mb-1'"
                  >
                    <div class="series-info">
                      <feather-icon
                        :icon="data.icon"
                        size="16"
                        class="mr-50"
                        :class="data.iconColor"
                      />
                      <span class="font-weight-bolder">{{ data.text }}</span>
                    </div>
                    <span>{{ data.result }}</span>
                  </div>
                </div>
              </b-card-body>
            </b-card>

          </b-col>  

          <b-col md="3">
  
            <b-card no-body class="custom-card-height">
                <b-card-body>
                <b-row>
                    <b-col
                    cols="12"
                    md="12"
                    class="mb-md-0 mb-2 margin_bottom_zero_mobile">
                    <h5 class="margin_bottom_zero_mobile">Task Summary</h5>

                    </b-col>
                </b-row>
                <b-row class="mt-2">
                    <b-col md="12">
                    <div class="pt-25">
                        <div
                        v-for="(data,index) in chartData.summaryData"
                        :key="index"
                        class="d-flex justify-content-between"
                        :class="index === Object.keys(chartData.summaryData).length - 1 ? '':'mb-1'"
                        >
                        <div class="series-info">
                            <feather-icon
                            :icon="data.icon"
                            size="16"
                            class="mr-50"
                            :class="data.iconColor"
                            />
                            <span class="font-weight-bolder">{{ data.text }}</span>
                        </div>
                        <span>{{ data.result }}</span>
                        </div>
                    </div>
                    </b-col>
                </b-row>
                </b-card-body>
            </b-card>
        </b-col>

        <b-col md="12">
            <b-card no-body class="mb-0">

                <div class="m-2">
                  <b-row>
                    <b-col cols="12" md="4" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                      <label>Show</label>
                      <v-select 
                        v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
                        :clearable="false" class="per-page-selector d-inline-block mx-50" @input="filterTable" 
                      />
                      <label>entries</label>
                    </b-col>
                    <!-- Search -->
                    <b-col cols="12" md="8">
                      <div class="d-flex align-items-center justify-content-end mobile-view">
                        <b-form-input
                          class="d-inline-block  mobile-margin-bottom mobile-margin-input mr-1"
                          v-model="searchQuery" placeholder="Search..." @input="filterTable"
                        />

                        <b-button :disabled="(siteData != null && siteData._id != 'all-site') && (items.length > 0) ? false:true" variant="warning" class="mobile-margin-bottom" @click="exportPDF()" v-if="checkPermission($route.name, 'Export')">
                          <span class="text-nowrap">Export PDF</span>
                        </b-button>
                      </div>
                    </b-col>
                  </b-row>
                </div>

                <b-table 
                  sticky-header ref="refUserListTable" class="common_big_tbl position-relative midTable" :items="items" responsive :fields="task_type == 'Service Request' ? tableColumns : tableColumns2" 
                  primary-key="id" :sort-by.sync="sortBy" show-empty empty-text="No matching records found" :sort-desc.sync="isSortDirDesc" :class="items.length <= 10 ? 'remove_overflow' : 'add_overflow'"
                >

                  <template #cell(unique_id)="items">
                      <span class="wordBreak imp-margin">
                        <div class="my-1">{{ items.item.unique_id | capitalize}}</div>
                      </span>
                  </template>
                  <template #cell(title)="items">
                      <span class="wordBreak">{{ items.item.title | capitalize}}</span>
                  </template>
                  <template #cell(site)="items">
                      <span class="wordBreak">{{ items.item.site.site_name | capitalize}}</span>
                  </template>
                  <template #cell(crew)="items">
                      <span class="wordBreak">{{ items.item.crew && items.item.crew.length > 0 ? items.item.crew[0].full_name : 'N/A' | capitalize}}</span>
                  </template>
                  <template #cell(locations)="items">
                      <span class="wordBreak" v-for="location in items.item.locations"> {{location.name | capitalize}}</span>
                  </template>
                  <template #cell(task_status)="items">
                      <b-badge pill :variant="variantColor(items.item.task_status)" class="text-capitalize">
                        {{ items.item.task_status | capitalize}}
                      </b-badge>
                  </template>
                  <template #cell(start)="items">
                      <span class="wordBreak">{{ sitedateTime(items.item.start,siteData.pdf_date_format,'' )}}</span>
                  </template>
                  <template #cell(end)="items">
                      <span class="wordBreak">{{ sitedateTime(items.item.end,siteData.pdf_date_format,'' )}}</span>
                  </template>

                </b-table>

                <div class="mx-2 mb-2">
                  <b-row>
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
                      <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }}</span>
                    </b-col>

                    <!-- Pagination -->
                    <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
                      <b-pagination
                        v-model="currentPage" :total-rows="totalRecords" :per-page="perPage" first-number last-number
                        class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item" @input="filterTable"
                      >
                        <template #prev-text>
                          <feather-icon icon="ChevronLeftIcon" size="18"/>
                        </template>
                        <template #next-text>
                          <feather-icon icon="ChevronRightIcon" size="18"/>
                        </template>
                      </b-pagination>
                    </b-col>
                  </b-row>
                </div>

              </b-card>
          </b-col>
  
        </b-row>
  
      </div>
  
    </div>
  </template>
  
  <script>
  import {
    BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,BAlert,BFormGroup, BBadge, BDropdown, BDropdownItem, 
    BPagination, BFormCheckbox, BCardBody, BFormSelectOption, BFormSelect, VBTooltip, BFormTextarea, BForm, BFormDatepicker, 
    BBreadcrumb, BFormRadio, BFormRadioGroup
  } from 'bootstrap-vue'
  import vSelect from 'vue-select'
  import store from '@/store'
  import { ref, onUnmounted } from '@vue/composition-api'
  import { GET_API,POST_API } from "../../../store/actions.type"
  import Datepicker from 'vuejs-datepicker';
  import moment from "moment-timezone";
  import VueApexCharts from 'vue-apexcharts';
  import { $themeColors } from '@themeConfig';
  import flatPickr from 'vue-flatpickr-component';
  
  export default {
    components: {
      Datepicker, BCardBody, BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink, BBadge, BDropdown,
      BDropdownItem, BPagination, BFormCheckbox, vSelect, BFormSelect, BFormSelectOption, VueApexCharts, BFormTextarea, 
      BAlert, BFormGroup, BForm, BFormDatepicker, BBreadcrumb, BFormRadio, BFormRadioGroup, flatPickr
    },
    directives: {
      'b-tooltip': VBTooltip,
      
    },
    data() {
      return {
        
        items:[],
        webUrl: process.env.VUE_APP_SERVER_URL,
        totalRecords : 0,
        siteData:null,
        frequencyData:null,

        frequencies:['Reactive','Ad-Hoc','Urgent'],
        sites:[],
        clearButton:true,
        clearButton2:true,
        start:moment.tz('Asia/Singapore').startOf('month').format('DD MMM YYYY'),
        end:moment.tz('Asia/Singapore').format('DD MMM YYYY'),
        locations:[],
        crew:'',
        alllocations:[],
        allcrews:[],
        status:'',
        chartData: {
            listData:[],
            summaryData:[],
        },
        series:[],
        customersPie: {
          chartOptions: {
              chart: {
                toolbar: {
                  show: false,
                },
              },
              labels: ['Open', 'In Progress', 'Closed','Validate','Failed'],

              dataLabels: {
                enabled: false,
              },
              legend: { show: false },
              stroke: {
                width: 0,
              },
              colors: [$themeColors.primary, $themeColors.warning, $themeColors.success,$themeColors.info,$themeColors.danger],
          },
        },
        
        popshowDismissibleAlert:false,
        error_message:'',
        supervisors:[],  
        pdf_type:'single',

        task_types: ['Service Request', 'Defects'],
        task_type: 'Service Request',

        date_range:moment.tz('Asia/Singapore').startOf('month').format('DD MMM YYYY') + ' to ' + moment.tz('Asia/Singapore').format('DD MMM YYYY'),
        date_config:{ 
            mode: 'range',
            dateFormat: 'd M Y',
            defaultDate: [
                moment.tz('Asia/Singapore').startOf('month').format('DD MMM YYYY'),
                moment.tz('Asia/Singapore').format('DD MMM YYYY')
            ]
        },

        // table vars 
        tableColumns: [
            { key: 'unique_id', label: 'ID', sortable: true , thStyle:  {width: '14%',fontSize:'10px', marginTop:'100px'}, tdStyle:  {paddingTop:'100px'}},
            { key: 'locations', label: 'Location', sortable: false , thStyle:  {width: '16%',fontSize:'10px'}},
            { key: 'site', label: 'Project Site', sortable: false , thStyle:  {width: '15%',fontSize:'10px'}},
            { key: 'title', label: 'Task', sortable: true , thStyle:  {width: '16%',fontSize:'10px'}},
            { key: 'crew', label: 'Crew', sortable: false , thStyle:  {width: '12%',fontSize:'10px'}},
            { key: 'start', label: 'Start Date', sortable: true , thStyle:  {width: '9%',fontSize:'10px'}},
            { key: 'end', label: 'End Date', sortable: true , thStyle:  {width: '9%',fontSize:'10px'}},
            { key: 'task_status', label: 'Status', sortable: true , thStyle:  {width: '9%',fontSize:'10px'}},
        ],
        tableColumns2: [
            { key: 'unique_id', label: 'ID', sortable: true , thStyle:  {width: '15%',fontSize:'10px'}},
            { key: 'locations', label: 'Location', sortable: false , thStyle:  {width: '16%',fontSize:'10px'}},
            { key: 'site', label: 'Project Site', sortable: false , thStyle:  {width: '15%',fontSize:'10px'}},
            { key: 'title', label: 'Defect', sortable: true , thStyle:  {width: '20%',fontSize:'10px'}},
            { key: 'start', label: 'Start Date', sortable: true , thStyle:  {width: '12%',fontSize:'10px'}},
            { key: 'end', label: 'End Date', sortable: true , thStyle:  {width: '12%',fontSize:'10px'}},
            { key: 'task_status', label: 'Status', sortable: true , thStyle:  {width: '10%',fontSize:'10px'}},
        ],
        searchQuery    : '',
        isSortDirDesc  : true,
        currentPage    : 1,
        sortBy         : 'id',
        perPageOptions : [10, 20, 50, 100],
        perPage        : 10,
        from           : null,
        to             : null, 
      }
    },

    methods : {
      exportPDF(){
        var tempLocationId   = this.locations.map(obj => obj._id);
        var tempLocationName = this.locations.map(obj => obj.name);

        var matchedID = this.allcrews.find((obj) => obj._id === this.crew);
        var crew_name = matchedID ? matchedID.full_name : 'null';

        let route = this.$router.resolve({ 
            name: 'export-task-analytics',
            params: {
                site          : this.siteData ? this.siteData._id : 'null',
                location      : tempLocationId.length > 0 ? tempLocationId.toString() : 'null',
                task_type     : this.task_type,
                frequency     : this.frequencyData && this.frequencyData.length > 0 ? this.frequencyData : 'null',
                crew          : this.crew.length > 0 ? this.crew : 'null',
                status        : this.status.length > 0 ? this.status : 'null',
                start         : moment(this.start).format('YYYY-MM-DD'),
                end           : moment(this.end).format('YYYY-MM-DD'),
                site_name     : this.siteData ? this.siteData.site_name : 'null',
                location_name : tempLocationName.length > 0 ? tempLocationName.toString() : 'null',
                date_format   : this.siteData ? this.siteData.pdf_date_format : 'null',
                crew_name     : crew_name
            }
        });
        window.open(route.href, '_blank');
      },

      dataList(){
        var api = this.task_type == 'Service Request' ? '/api/task-analytics' : '/api/defect-analytics';
        return this.$store.dispatch(POST_API, {
            data:{
              site:this.siteData ? this.siteData._id : null,
              frequency:this.frequencyData,
              locations : this.locations,
              crew: this.crew,
              start:moment(this.start).format('YYYY-MM-DD'),
              end:moment(this.end).format('YYYY-MM-DD'),
              status:this.status,
              role:this.$store.getters.currentUser.role,
              om_sites:this.$store.getters.currentUser.om_sites,
              report:'service',

              //  table vars 
              keyword       : this.searchQuery,
              page          : this.currentPage,
              rowsPerPage   : this.perPage,
              sortBy        : this.sortBy,
              sortDirection : this.isSortDirDesc
            },
            api: api
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
            } else {
              this.series =  this.$store.getters.getResults.data.series;
              this.chartData.listData = this.$store.getters.getResults.data.listData;
              this.chartData.summaryData = this.$store.getters.getResults.data.summaryData;
            
              // table vars
              this.items        = this.$store.getters.getResults.data.docs;
              this.totalRecords = this.$store.getters.getResults.data.total;
              this.perPage      = this.$store.getters.getResults.data.limit;
              this.from         = this.$store.getters.getResults.data.from
              this.to           = this.$store.getters.getResults.data.to

              return this.items;
            }
        });
      },

      variantColor(status){
        if (status == 'open') {
          return 'primary';
        }else if(status == 'in_progress'){
          return 'warning'
        }else if(status == 'validate'){
          return 'info'
        }else if(status == 'failed'){
          return 'danger'
        }else{
          return 'success';
        }
      },

      filterTable(){  
        this.dataList();
      },
      
      allSites(){
        return this.$store.dispatch(POST_API, {
           data:{
              role:this.$store.getters.currentUser.role,
              om_sites:this.$store.getters.currentUser.om_sites,
           },
           api: '/api/all-sites'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
            } else {
                this.sites = this.$store.getters.getResults.data;
                
                var obj = {
                  _id:'all-site',
                  site_name:'All Project Sites',
                  pdf_date_format: 'DD MMM YYYY',
                  pdf_time_format: 'HH:mm'
                }
                this.sites.unshift(obj);
  
                this.siteData = this.defaultSite(this.sites);
                this.allLocations();
                this.allCrews();
                this.filterTable();
  
                return this.sites;
            }
        });
      },

      resetData(){
        this.locations = [];
        this.crew = '';
  
        this.alllocations = [];
        this.allcrews =[];
        
        this.allLocations();
        this.allCrews();
        this.filterTable()
      },

      allLocations(){
        return this.$store.dispatch(POST_API, {
             data:{
               site:this.siteData ? this.siteData._id : null,
             },
             api: '/api/all-locations'
          })
          .then(() => {
              if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showDismissibleAlert = true;
                  window.scrollTo(0,0);
              } else {
                  this.showDismissibleAlert = false;
                  var data  = this.$store.getters.getResults.data;
                  
                  this.alllocations = data;
  
                  if (this.$route.params.locations && this.$route.params.locations != 'null') {
            
                      var locations = this.$route.params.locations.split(',');
                      //console.log(locations);
  
                      var selectedLocation = [];
                      
                      this.alllocations.forEach(function(item){
                          
                          if (locations.indexOf(item._id) >= 0) {
                             selectedLocation.push(item);
                          }
                      })
                      this.locations = selectedLocation;
                  }
  
  
                  return this.alllocations;
              }
          });
      },

      allCrews(){
        return this.$store.dispatch(POST_API, {
             data:{
               site:this.siteData ? this.siteData._id : null,
             },
             api: '/api/all-crew'
          })
          .then(() => {
              if (this.$store.getters.containsErrors) {
                  this.error_message = this.$store.getters.getErrors;
                  this.showDismissibleAlert = true;
                  //window.scrollTo(0,0);
              } else {
                  this.showDismissibleAlert = false;
                  var data  = this.$store.getters.getResults.data;
                  
                  this.allcrews = data;
  
                  
                  return this.allcrews;
              }
          });
      },
      updateStart(){
        
        this.end = moment(this.start).tz('Asia/Singapore').toDate();
        this.filterTable();
  
      },
      disabledDates(){  
        if (this.start) {
          return moment(this.start).format('YYYY-MM-DD');
        }else{          
          return moment().format('YYYY-MM-DD');          
        }
      },

      resetall(){        
        this.siteData = null;
        this.frequencyData = null;
        this.locations = [];
        this.crew = '';
        this.start = null;
        this.end= null;
        this.status = '';
        this.alllocations = [];
        this.allcrews =[];
  
        this.filterTable();
      },

        setDate(){
			var date = this.date_range.split(' to ');
            // console.log(date)
	        if (date[1]) {
	            this.start = moment(new Date(date[0])).format('YYYY-MM-DD');
	            this.end = moment(new Date(date[1])).format('YYYY-MM-DD');
	        }else{
	            this.start = moment(new Date(date[0])).format('YYYY-MM-DD');
	            this.end = moment(new Date(date[0])).format('YYYY-MM-DD');
	        }
            this.filterTable();
		},

      breadCrumb(){
        var item = [{
          to:{name:'client-dashboard'},
          text: 'Dashboard',
        },{
          to:null,
          text: 'Service Request',
        },{
          to:null,
          text:'Statistics',
          active:true
        }];
        return item;
      },
    
    },
    mounted(){
      this.allSites();
      this.setDate();
    }
    
  }
  </script>
  
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
  